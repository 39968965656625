import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "About Me",
  "author": "David Peet",
  "type": "about"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋 Hi, I'm David!`}</h1>
    <p>{`I'm a 4th year Computer Science student at the University of Waterloo. Last term, I was working at Huawei on lightweight cryptography.
I'm interested in systems development including OS and compiler development as well as backend web development, and I'm always interested in learning!`}</p>
    <p>{`I'm looking for Fall 2022 (Sept-Dec) internship opportunities! Feel free to reach out through `}<a parentName="p" {...{
        "href": "mailto:dapeet@uwaterloo.ca"
      }}>{`email - (dapeet@uwaterloo.ca)`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/dapeet/"
      }}>{`LinkedIn`}</a>{` to chat!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      